import React, { useState } from 'react';
import axios from 'axios';
import * as globals from './../../../components/all.js';
import { Dialog } from '../../../components/Dialog.jsx';

const Modal_configuracion_vectores = ({ onClose, data, onVectorAdded}) => {
    const [name, setName] = useState('');
    const [svg, setSvg] = useState(null);
    const [dialogProps, setDialogProps] = useState({show: false});
    const user = JSON.parse(localStorage.getItem('user'));
    let path_api = localStorage.getItem('path_api');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(svg.type != 'image/svg+xml'){
            setDialogProps({message: `Formato incorrecto ${svg.type}`, show: true});
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('type', data.type);
        formData.append('svg', svg);

        globals.openViewCharge();
        await axios.post(`${path_api}/api/config/vectores?lvl=${user.lvl}`, formData)
        .then((response) => {
            onVectorAdded(response.data.vector);
            onClose();
        }).catch((error) => {
            console.error('Error al guardar:', error);
            setDialogProps({message: 'Hubo un error al agregar', show: true});
        });
        globals.closeViewCharge();
    };

    return <>
        <div className='modal-container'>
            <div className="modal">
                <h2>Agregar {data.type}</h2>
                <form onSubmit={handleSubmit}>
                    <div className='flex-column gap-5'>
                        <label>Nombre: </label>
                        <input
                            type="text"
                            placeholder="Nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>

                    <div className='flex-column gap-5'>
                        <label>Archivo SVG: </label>
                        <input
                            type="file"
                            accept=".svg"
                            onChange={(e) => setSvg(e.target.files[0])}
                            required
                        />
                    </div>
                    <div className='flex-row gap-20 flex-end'>
                        <button className='cancel' onClick={onClose}>Cerrar</button>
                        <button type="submit">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
        
        <Dialog
            {...dialogProps}
            onClose={() => {
                setDialogProps({ show: false })
            }}
        />
    </>
};

export default Modal_configuracion_vectores;
