import './Dialog.css'

export const Dialog = ({show, message, onClose})=>{
    return show && <div className='modal-container'>
    <div className='modal'>
        <h2>Mensaje del sistema</h2>
        <p>{message}</p>
        <div className='flex-row gap-20 flex-end'>
            <button className='cancel' onClick={onClose}>Cerrar</button>
        </div>
    </div>
</div>
}