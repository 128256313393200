import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as globals from './../components/all.js';
import { Link } from 'react-router-dom';
import { Dialog } from '../components/Dialog.jsx';

const Vendedor_proyectos = () => {
    const [proyectos, setProyectos] = useState([]);
    const [search, setSearch] = useState('');
    const [newProyect, setNewProyect] = useState({ name: '', email: '', password: '', confirmPassword: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [dialogProps, setDialogProps] = useState({ show: false })
    let path_api = localStorage.getItem('path_api');
    let user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!user) return;
        fetchProyects();
    }, [search, currentPage]);

    const fetchProyects = async () => {
        axios.get(`${path_api}/api/proyectos?type=all&lvl=${user.lvl}&id_user=${user.id}`, { params: { search, page: currentPage } })
            .then(response => {
                setProyectos(response.data.data);
                setTotalPages(response.data.last_page);
            }).catch(error => {
                console.error('Hubo un error al obtener los proyectos:', error);
            });

    };

    const handleAddProyect = async () => {
        globals.openViewCharge();
        await axios.post(`${path_api}/api/proyectos?lvl=${user.lvl}&id_user=${user.id}`, newProyect)
            .then(response => {
                setIsModalOpen(false);
                fetchProyects();
            })
            .catch(error => {
                console.error('Hubo un error al agregar:', error);
                setDialogProps({ message: 'Hubo un error al agregar', show: true });
            });
        globals.closeViewCharge();
    };

    const changePage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return <>
        <div className='flex-column gap-40'>
            <div className='container-nav-search'>
                <div>
                    <label>Filtrar</label>
                    <input
                        type="text"
                        placeholder="Escribe para filtrar por nombre"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <button onClick={() => {
                    setNewProyect({ name: '', name_client: '', date_start: '' });
                    setIsModalOpen(true);
                }}>
                    Nuevo
                </button>
            </div>

            {isModalOpen && (
                <div className='modal-container'>
                    <div className="modal">
                        <h2>Datos</h2>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label>Nombre del proyecto: </label>
                                <input
                                    type="text"
                                    placeholder="Nombre del proyecto"
                                    value={newProyect.name}
                                    onChange={(e) => setNewProyect({ ...newProyect, name: e.target.value })}
                                />
                            </div>
                            <div className='flex-column gap-5'>
                                <label>Nombre del cliente: </label>
                                <input
                                    type="text"
                                    placeholder="Nombre del cliente"
                                    value={newProyect.name_client}
                                    onChange={(e) => setNewProyect({ ...newProyect, name_client: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label>Fecha de Inicio: </label>
                                <input
                                    type="date"
                                    placeholder="Inicio"
                                    value={newProyect.date_start}
                                    onChange={(e) => setNewProyect({ ...newProyect, date_start: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='flex-row gap-20 flex-end'>
                            <button className='cancel' onClick={() => setIsModalOpen(false)}>Cancelar</button>
                            <button onClick={handleAddProyect}>
                                Crear
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className='container-table'>
                <table>
                    <tbody>
                        {proyectos.map((proyecto) => (
                            <tr key={proyecto.id}>
                                <td style={{ maxWidth: '80px' }}>
                                    <Link to={`/items/${proyecto.id}`}>
                                        <svg width="60" height="60" viewBox="0 0 100 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.375 0.5C6.8886 0.5 4.50403 1.48772 2.74587 3.24587C0.98772 5.00403 0 7.3886 0 9.875L0 22.375H37.5C38.3288 22.375 39.1237 22.7042 39.7097 23.2903C40.2958 23.8763 40.625 24.6712 40.625 25.5C40.625 27.0812 41.125 29.525 42.5375 31.4875C43.8313 33.2875 46.0187 34.875 50 34.875C53.9813 34.875 56.1688 33.2875 57.4625 31.4875C58.875 29.525 59.375 27.0812 59.375 25.5C59.375 24.6712 59.7042 23.8763 60.2903 23.2903C60.8763 22.7042 61.6712 22.375 62.5 22.375H100V9.875C100 7.3886 99.0123 5.00403 97.2541 3.24587C95.496 1.48772 93.1114 0.5 90.625 0.5H9.375Z" fill="var(--color-black)" />
                                            <path d="M100 28.625H65.3063C64.854 30.9671 63.9103 33.1868 62.5375 35.1375C60.0812 38.55 56.0187 41.125 50 41.125C43.9813 41.125 39.9187 38.55 37.4625 35.1375C36.0897 33.1868 35.146 30.9671 34.6938 28.625H0V66.125C0 68.6114 0.98772 70.996 2.74587 72.7541C4.50403 74.5123 6.8886 75.5 9.375 75.5H90.625C93.1114 75.5 95.496 74.5123 97.2541 72.7541C99.0123 70.996 100 68.6114 100 66.125V28.625Z" fill="var(--color-black)" />
                                        </svg>
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/items/${proyecto.id}`} className='flex-column gap-10'>
                                        <div>{proyecto.name}</div>
                                        <div>{proyecto.date_start ? proyecto.date_start.slice(0, 10) : <s>Sin fecha</s>}</div>
                                        <div>{proyecto.name_client}</div>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination">
                    <div>
                        <span>Mostrando <b>{proyectos.length}</b> proyectos</span>
                    </div>
                    <div>
                        <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>Página <b>{currentPage}</b> de <b>{totalPages}</b></span>
                        <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <Dialog
            {...dialogProps}
            onClose={() => {
                setDialogProps({ show: false })
            }}
        />
    </>
};

export default Vendedor_proyectos;