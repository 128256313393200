import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as globals from './../components/all.js';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dialog } from '../components/Dialog.jsx';

const Vendedor_items = () => {
    const { id } = useParams();
    const [items, setItems] = useState([]);
    const [lineas, setLineas] = useState([]);
    const [colores, setColores] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);
    const [search, setSearch] = useState('');
    const [newItem, setNewItem] = useState({ type: '', width: '', height: '', quantity: '', line: '', color: '', location: '', crystal: '', chiffchaff: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [dialogProps, setDialogProps] = useState({ show: false });
    let path_api = localStorage.getItem('path_api');
    let user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!user) return;
        fetchItems();
    }, [search, currentPage]);


    useEffect(() => {
        axios.get(`${path_api}/api/config/linea?lvl=${user.lvl}`)
            .then(response => {
                setLineas(response.data);
            }).catch(error => {
                console.error('Hubo un error al obtener las lineas:', error);
            });

        axios.get(`${path_api}/api/config/color?lvl=${user.lvl}`)
            .then(response => {
                setColores(response.data);
            }).catch(error => {
                console.error('Hubo un error al obtener los colores:', error);
            });

        axios.get(`${path_api}/api/config/ubicacion?lvl=${user.lvl}`)
            .then(response => {
                setUbicaciones(response.data);
            }).catch(error => {
                console.error('Hubo un error al obtener las ubicaciones:', error);
            });
    }, []);

    const fetchItems = async () => {
        axios.get(`${path_api}/api/items?type=all&lvl=${user.lvl}&id_proyect=${id}`, { params: { search, page: currentPage } })
            .then(response => {
                setItems(response.data.data);
                setTotalPages(response.data.last_page);
            }).catch(error => {
                console.error('Hubo un error al obtener los items:', error);
            });

    };

    const handleAddItem = async () => {
        globals.openViewCharge();
        await axios.post(`${path_api}/api/items?lvl=${user.lvl}&id_proyect=${id}&path=${path_api}`, newItem)
            .then(response => {
                setIsModalOpen(false);
                fetchItems();
            })
            .catch(error => {
                console.error('Hubo un error al agregar:', error);
                setDialogProps({ message: 'Hubo un error al agregar', show: true });
            });
        globals.closeViewCharge();
    };

    const changePage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return <>
        <div className='flex-column gap-40'>
            <div className='container-nav-search'>
                <div>
                    <label>Filtrar</label>
                    <input
                        type="text"
                        placeholder="Ubicacion, ancho o alto"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <button onClick={() => {
                    setNewItem({ type: '', width: '', height: '', quantity: '', line: '', color: '', location: '', crystal: '', chiffchaff: '' });
                    setIsModalOpen(true);
                }}>
                    Nuevo
                </button>
            </div>

            {isModalOpen && (
                <div className='modal-container'>
                    <div className="modal">
                        <h2>Datos</h2>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label className='label-required'>Tipo: </label>
                                <select
                                    value={newItem.name}
                                    onChange={(e) => setNewItem({ ...newItem, type: e.target.value })}
                                >
                                    <option value="">Seleccionar tipo</option>
                                    <option value="batiente">Batiente</option>
                                    <option value="deslizante">Deslizante</option>
                                </select>
                            </div>
                            <div className='flex-column gap-5'>
                                <label className='label-required'>Ancho: </label>
                                <input
                                    type="number"
                                    placeholder=""
                                    value={newItem.width}
                                    onChange={(e) => setNewItem({ ...newItem, width: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label className='label-required'>Altura: </label>
                                <input
                                    type="number"
                                    placeholder=""
                                    value={newItem.height}
                                    onChange={(e) => setNewItem({ ...newItem, height: e.target.value })}
                                />
                            </div>
                            <div className='flex-column gap-5'>
                                <label className='label-required'>Cantidad: </label>
                                <input
                                    type="number"
                                    placeholder=""
                                    value={newItem.quantity}
                                    onChange={(e) => setNewItem({ ...newItem, quantity: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label>Linea: </label>
                                <select
                                    value={newItem.line}
                                    onChange={(e) => setNewItem({ ...newItem, line: e.target.value })}
                                >
                                    <option value="">Seleccionar tipo</option>
                                    {lineas.map((option, index) => (
                                        <option key={index} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex-column gap-5'>
                                <label>Color: </label>
                                <select
                                    value={newItem.color}
                                    onChange={(e) => setNewItem({ ...newItem, color: e.target.value })}
                                >
                                    <option value="">Seleccionar tipo</option>
                                    {colores.map((option, index) => (
                                        <option key={index} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label>Ubicacion: </label>
                                <select
                                    value={newItem.location}
                                    onChange={(e) => setNewItem({ ...newItem, location: e.target.value })}
                                >
                                    <option value="">Seleccionar tipo</option>
                                    {ubicaciones.map((option, index) => (
                                        <option key={index} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex-column gap-5'>
                                <label>Cristal: </label>
                                <input
                                    type="text"
                                    placeholder=""
                                    value={newItem.crystal}
                                    onChange={(e) => setNewItem({ ...newItem, crystal: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label>Mosquitero: </label>
                                <input
                                    type="text"
                                    placeholder=""
                                    value={newItem.chiffchaff}
                                    onChange={(e) => setNewItem({ ...newItem, chiffchaff: e.target.value })}
                                />
                            </div>
                            <div className='flex-column gap-5'></div>
                        </div>
                        <div className='flex-row gap-20 flex-end'>
                            <button className='cancel' onClick={() => setIsModalOpen(false)}>Cancelar</button>
                            <button onClick={handleAddItem}>
                                Crear
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className='container-table'>
                <table>
                    <tbody>
                        {items.map((item) => (
                            <tr key={item.id}>
                                <td style={{ maxWidth: '120px' }}>
                                    <a onClick={() => {
                                        const currentUrl = window.location.href;
                                        window.location.href = `/editor/?id=${item.id}&type=${item.type}&url=${encodeURIComponent(currentUrl)}`;
                                    }}>
                                        <img src={item.miniature} alt='miniatura' className='miniatura' />
                                    </a>
                                </td>
                                <td>
                                    <a onClick={() => {
                                        const currentUrl = window.location.href;
                                        window.location.href = `/editor/?id=${item.id}&type=${item.type}&url=${encodeURIComponent(currentUrl)}`;
                                    }} className='flex-column gap-10'>
                                        <div><b>Cantidad: </b>{item.quantity}</div>
                                        <div><b>Medidas (W*H): </b>{item.width} * {item.height}</div>
                                        <div><b>Ubicación: </b>{item.location}</div>
                                        <div><b>tipo: </b>{item.type}</div>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination">
                    <div>
                        <span>Mostrando <b>{items.length}</b> items</span>
                    </div>
                    <div>
                        <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>Página <b>{currentPage}</b> de <b>{totalPages}</b></span>
                        <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog
            {...dialogProps}
            onClose={() => {
                setDialogProps({ show: false })
            }}
        />
    </>;
};

export default Vendedor_items;