import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal_configuracion_vectores from './complements/Modal_configuracion_vectores';
import Modal_configuracion_other from './complements/Modal_configuracion_other';
import * as globals from './../../components/all.js';
import { Dialog } from '../../components/Dialog.jsx';

const Dashboard_admin_configuracion = () => {
    const [batientes, setBatientes] = useState([]);
    const [deslizantes, setDeslizantes] = useState([]);
    const [lineas, setLineas] = useState([]);
    const [colores, setColores] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);
    const [modalVisible, setModalVisible] = useState({ vectores: false, other: false });
    const [modalData, setModalData] = useState(null);
    const [dialogProps, setDialogProps] = useState({ show: false })
    const user = JSON.parse(localStorage.getItem('user'));
    let path_api = localStorage.getItem('path_api');

    useEffect(() => {
        axios.get(`${path_api}/api/config/vectores?lvl=${user.lvl}`)
            .then((response) => {
                setBatientes(response.data.batientes);
                setDeslizantes(response.data.deslizantes);
            }).catch((error) => {
                console.error('Error al obtener vectores:', error);
            });

        axios.get(`${path_api}/api/config/linea?lvl=${user.lvl}`)
            .then((response) => {
                setLineas(response.data);
            }).catch((error) => {
                console.error('Error al obtener:', error);
            });

        axios.get(`${path_api}/api/config/color?lvl=${user.lvl}`)
            .then((response) => {
                setColores(response.data);
            }).catch((error) => {
                console.error('Error al obtener:', error);
            });

        axios.get(`${path_api}/api/config/ubicacion?lvl=${user.lvl}`)
            .then((response) => {
                setUbicaciones(response.data);
            }).catch((error) => {
                console.error('Error al obtener:', error);
            });

    }, []);

    const handleAddVector = (type) => {
        setModalData({ type });
        setModalVisible({ vectores: true, other: false });
    };

    const handleVectorAdded = (newVector) => {
        if (newVector.type === 'batiente') {
            setBatientes([...batientes, newVector]);
        } else if (newVector.type === 'deslizante') {
            setDeslizantes([...deslizantes, newVector]);
        }
    };

    const handleDeleteVector = async (id, type) => {
        globals.openViewCharge();
        await axios.delete(`${path_api}/api/config/vectores/${id}?lvl=${user.lvl}`)
            .then(response => {
                if (type == "batiente")
                    setBatientes(batientes.filter(vector => vector.id !== id));
                else if (type == "deslizante")
                    setDeslizantes(deslizantes.filter(vector => vector.id !== id));
            })
            .catch(error => {
                console.error('Hubo un error al eliminar rl vector:', error);
                setDialogProps({ message: 'Hubo un error al eliminar el vector', show: true });
            });
        globals.closeViewCharge();
    };

    const handleAddOther = (type) => {
        setModalData({ type });
        setModalVisible({ vectores: false, other: true });
    };

    const handleOtherAdded = (newOther) => {
        if (newOther.type === 'linea')
            setLineas([...lineas, newOther]);
        else if (newOther.type === 'color')
            setColores([...colores, newOther]);
        else if (newOther.type === 'ubicacion')
            setUbicaciones([...ubicaciones, newOther]);
    };

    const handleDeleteOther = async (id, type) => {
        globals.openViewCharge();
        await axios.delete(`${path_api}/api/config/${type}/${id}?lvl=${user.lvl}`)
            .then(response => {
                if (type == "linea")
                    setLineas(lineas.filter(other => other.id !== id));
                else if (type == "color")
                    setColores(colores.filter(other => other.id !== id));
                else if (type == "ubicacion")
                    setUbicaciones(ubicaciones.filter(other => other.id !== id));
            })
            .catch(error => {
                console.error('Hubo un error al eliminar rl vector:', error);
                setDialogProps({ message: 'Hubo un error al eliminar', show: true });
            });
        globals.closeViewCharge();
    };

    return <>
        <div className='flex-column gap-40'>
            <h2>Configuración</h2>
            <div className='grid-container-conf'>
                <div className='container-opt'>
                    <div className='header-section'>
                        <h3>Linea</h3>
                        <button onClick={() => handleAddOther('linea')}>
                            <svg width="20" height="20" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38 0.5C38.8288 0.5 39.6237 0.829241 40.2097 1.41529C40.7958 2.00134 41.125 2.7962 41.125 3.625V34.875H72.375C73.2038 34.875 73.9987 35.2042 74.5847 35.7903C75.1708 36.3763 75.5 37.1712 75.5 38C75.5 38.8288 75.1708 39.6237 74.5847 40.2097C73.9987 40.7958 73.2038 41.125 72.375 41.125H41.125V72.375C41.125 73.2038 40.7958 73.9987 40.2097 74.5847C39.6237 75.1708 38.8288 75.5 38 75.5C37.1712 75.5 36.3763 75.1708 35.7903 74.5847C35.2042 73.9987 34.875 73.2038 34.875 72.375V41.125H3.625C2.7962 41.125 2.00134 40.7958 1.41529 40.2097C0.829241 39.6237 0.5 38.8288 0.5 38C0.5 37.1712 0.829241 36.3763 1.41529 35.7903C2.00134 35.2042 2.7962 34.875 3.625 34.875H34.875V3.625C34.875 2.7962 35.2042 2.00134 35.7903 1.41529C36.3763 0.829241 37.1712 0.5 38 0.5Z" fill="var(--color-white)" />
                            </svg>
                        </button>
                    </div>
                    <table>
                        <tbody>
                            {lineas.map((linea) => (
                                <tr key={linea.id}>
                                    <td>{linea.name}</td>
                                    <td style={{ maxWidth: '50px' }}>
                                        <button className='button-delete' onClick={() => handleDeleteOther(linea.id, 'linea')}>
                                            <svg width="20" height="20" viewBox="0 0 82 94" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.625 6.25C4.9674 6.25 3.37768 6.90848 2.20558 8.08058C1.03348 9.25268 0.375 10.8424 0.375 12.5V18.75C0.375 20.4076 1.03348 21.9973 2.20558 23.1694C3.37768 24.3415 4.9674 25 6.625 25H9.75V81.25C9.75 84.5652 11.067 87.7446 13.4112 90.0888C15.7554 92.433 18.9348 93.75 22.25 93.75H59.75C63.0652 93.75 66.2446 92.433 68.5888 90.0888C70.933 87.7446 72.25 84.5652 72.25 81.25V25H75.375C77.0326 25 78.6223 24.3415 79.7944 23.1694C80.9665 21.9973 81.625 20.4076 81.625 18.75V12.5C81.625 10.8424 80.9665 9.25268 79.7944 8.08058C78.6223 6.90848 77.0326 6.25 75.375 6.25H53.5C53.5 4.5924 52.8415 3.00269 51.6694 1.83058C50.4973 0.65848 48.9076 0 47.25 0L34.75 0C33.0924 0 31.5027 0.65848 30.3306 1.83058C29.1585 3.00269 28.5 4.5924 28.5 6.25H6.625ZM25.375 31.25C26.2038 31.25 26.9987 31.5792 27.5847 32.1653C28.1708 32.7513 28.5 33.5462 28.5 34.375V78.125C28.5 78.9538 28.1708 79.7487 27.5847 80.3347C26.9987 80.9208 26.2038 81.25 25.375 81.25C24.5462 81.25 23.7513 80.9208 23.1653 80.3347C22.5792 79.7487 22.25 78.9538 22.25 78.125V34.375C22.25 33.5462 22.5792 32.7513 23.1653 32.1653C23.7513 31.5792 24.5462 31.25 25.375 31.25ZM41 31.25C41.8288 31.25 42.6237 31.5792 43.2097 32.1653C43.7958 32.7513 44.125 33.5462 44.125 34.375V78.125C44.125 78.9538 43.7958 79.7487 43.2097 80.3347C42.6237 80.9208 41.8288 81.25 41 81.25C40.1712 81.25 39.3763 80.9208 38.7903 80.3347C38.2042 79.7487 37.875 78.9538 37.875 78.125V34.375C37.875 33.5462 38.2042 32.7513 38.7903 32.1653C39.3763 31.5792 40.1712 31.25 41 31.25ZM59.75 34.375V78.125C59.75 78.9538 59.4208 79.7487 58.8347 80.3347C58.2487 80.9208 57.4538 81.25 56.625 81.25C55.7962 81.25 55.0013 80.9208 54.4153 80.3347C53.8292 79.7487 53.5 78.9538 53.5 78.125V34.375C53.5 33.5462 53.8292 32.7513 54.4153 32.1653C55.0013 31.5792 55.7962 31.25 56.625 31.25C57.4538 31.25 58.2487 31.5792 58.8347 32.1653C59.4208 32.7513 59.75 33.5462 59.75 34.375Z" fill="var(--color-white)" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className='container-opt'>
                    <div className='header-section'>
                        <h3>Color</h3>
                        <button onClick={() => handleAddOther('color')}>
                            <svg width="20" height="20" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38 0.5C38.8288 0.5 39.6237 0.829241 40.2097 1.41529C40.7958 2.00134 41.125 2.7962 41.125 3.625V34.875H72.375C73.2038 34.875 73.9987 35.2042 74.5847 35.7903C75.1708 36.3763 75.5 37.1712 75.5 38C75.5 38.8288 75.1708 39.6237 74.5847 40.2097C73.9987 40.7958 73.2038 41.125 72.375 41.125H41.125V72.375C41.125 73.2038 40.7958 73.9987 40.2097 74.5847C39.6237 75.1708 38.8288 75.5 38 75.5C37.1712 75.5 36.3763 75.1708 35.7903 74.5847C35.2042 73.9987 34.875 73.2038 34.875 72.375V41.125H3.625C2.7962 41.125 2.00134 40.7958 1.41529 40.2097C0.829241 39.6237 0.5 38.8288 0.5 38C0.5 37.1712 0.829241 36.3763 1.41529 35.7903C2.00134 35.2042 2.7962 34.875 3.625 34.875H34.875V3.625C34.875 2.7962 35.2042 2.00134 35.7903 1.41529C36.3763 0.829241 37.1712 0.5 38 0.5Z" fill="var(--color-white)" />
                            </svg>
                        </button>
                    </div>
                    <table>
                        <tbody>
                            {colores.map((color) => (
                                <tr key={color.id}>
                                    <td>{color.name}</td>
                                    <td style={{ maxWidth: '50px' }}>
                                        <button className='button-delete' onClick={() => handleDeleteOther(color.id, 'color')}>
                                            <svg width="20" height="20" viewBox="0 0 82 94" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.625 6.25C4.9674 6.25 3.37768 6.90848 2.20558 8.08058C1.03348 9.25268 0.375 10.8424 0.375 12.5V18.75C0.375 20.4076 1.03348 21.9973 2.20558 23.1694C3.37768 24.3415 4.9674 25 6.625 25H9.75V81.25C9.75 84.5652 11.067 87.7446 13.4112 90.0888C15.7554 92.433 18.9348 93.75 22.25 93.75H59.75C63.0652 93.75 66.2446 92.433 68.5888 90.0888C70.933 87.7446 72.25 84.5652 72.25 81.25V25H75.375C77.0326 25 78.6223 24.3415 79.7944 23.1694C80.9665 21.9973 81.625 20.4076 81.625 18.75V12.5C81.625 10.8424 80.9665 9.25268 79.7944 8.08058C78.6223 6.90848 77.0326 6.25 75.375 6.25H53.5C53.5 4.5924 52.8415 3.00269 51.6694 1.83058C50.4973 0.65848 48.9076 0 47.25 0L34.75 0C33.0924 0 31.5027 0.65848 30.3306 1.83058C29.1585 3.00269 28.5 4.5924 28.5 6.25H6.625ZM25.375 31.25C26.2038 31.25 26.9987 31.5792 27.5847 32.1653C28.1708 32.7513 28.5 33.5462 28.5 34.375V78.125C28.5 78.9538 28.1708 79.7487 27.5847 80.3347C26.9987 80.9208 26.2038 81.25 25.375 81.25C24.5462 81.25 23.7513 80.9208 23.1653 80.3347C22.5792 79.7487 22.25 78.9538 22.25 78.125V34.375C22.25 33.5462 22.5792 32.7513 23.1653 32.1653C23.7513 31.5792 24.5462 31.25 25.375 31.25ZM41 31.25C41.8288 31.25 42.6237 31.5792 43.2097 32.1653C43.7958 32.7513 44.125 33.5462 44.125 34.375V78.125C44.125 78.9538 43.7958 79.7487 43.2097 80.3347C42.6237 80.9208 41.8288 81.25 41 81.25C40.1712 81.25 39.3763 80.9208 38.7903 80.3347C38.2042 79.7487 37.875 78.9538 37.875 78.125V34.375C37.875 33.5462 38.2042 32.7513 38.7903 32.1653C39.3763 31.5792 40.1712 31.25 41 31.25ZM59.75 34.375V78.125C59.75 78.9538 59.4208 79.7487 58.8347 80.3347C58.2487 80.9208 57.4538 81.25 56.625 81.25C55.7962 81.25 55.0013 80.9208 54.4153 80.3347C53.8292 79.7487 53.5 78.9538 53.5 78.125V34.375C53.5 33.5462 53.8292 32.7513 54.4153 32.1653C55.0013 31.5792 55.7962 31.25 56.625 31.25C57.4538 31.25 58.2487 31.5792 58.8347 32.1653C59.4208 32.7513 59.75 33.5462 59.75 34.375Z" fill="var(--color-white)" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className='container-opt'>
                    <div className='header-section'>
                        <h3>Ubicacion</h3>
                        <button onClick={() => handleAddOther('ubicacion')}>
                            <svg width="20" height="20" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38 0.5C38.8288 0.5 39.6237 0.829241 40.2097 1.41529C40.7958 2.00134 41.125 2.7962 41.125 3.625V34.875H72.375C73.2038 34.875 73.9987 35.2042 74.5847 35.7903C75.1708 36.3763 75.5 37.1712 75.5 38C75.5 38.8288 75.1708 39.6237 74.5847 40.2097C73.9987 40.7958 73.2038 41.125 72.375 41.125H41.125V72.375C41.125 73.2038 40.7958 73.9987 40.2097 74.5847C39.6237 75.1708 38.8288 75.5 38 75.5C37.1712 75.5 36.3763 75.1708 35.7903 74.5847C35.2042 73.9987 34.875 73.2038 34.875 72.375V41.125H3.625C2.7962 41.125 2.00134 40.7958 1.41529 40.2097C0.829241 39.6237 0.5 38.8288 0.5 38C0.5 37.1712 0.829241 36.3763 1.41529 35.7903C2.00134 35.2042 2.7962 34.875 3.625 34.875H34.875V3.625C34.875 2.7962 35.2042 2.00134 35.7903 1.41529C36.3763 0.829241 37.1712 0.5 38 0.5Z" fill="var(--color-white)" />
                            </svg>
                        </button>
                    </div>
                    <table>
                        <tbody>
                            {ubicaciones.map((ubicacion) => (
                                <tr key={ubicacion.id}>
                                    <td>{ubicacion.name}</td>
                                    <td style={{ maxWidth: '50px' }}>
                                        <button className='button-delete' onClick={() => handleDeleteOther(ubicacion.id, 'ubicacion')}>
                                            <svg width="20" height="20" viewBox="0 0 82 94" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.625 6.25C4.9674 6.25 3.37768 6.90848 2.20558 8.08058C1.03348 9.25268 0.375 10.8424 0.375 12.5V18.75C0.375 20.4076 1.03348 21.9973 2.20558 23.1694C3.37768 24.3415 4.9674 25 6.625 25H9.75V81.25C9.75 84.5652 11.067 87.7446 13.4112 90.0888C15.7554 92.433 18.9348 93.75 22.25 93.75H59.75C63.0652 93.75 66.2446 92.433 68.5888 90.0888C70.933 87.7446 72.25 84.5652 72.25 81.25V25H75.375C77.0326 25 78.6223 24.3415 79.7944 23.1694C80.9665 21.9973 81.625 20.4076 81.625 18.75V12.5C81.625 10.8424 80.9665 9.25268 79.7944 8.08058C78.6223 6.90848 77.0326 6.25 75.375 6.25H53.5C53.5 4.5924 52.8415 3.00269 51.6694 1.83058C50.4973 0.65848 48.9076 0 47.25 0L34.75 0C33.0924 0 31.5027 0.65848 30.3306 1.83058C29.1585 3.00269 28.5 4.5924 28.5 6.25H6.625ZM25.375 31.25C26.2038 31.25 26.9987 31.5792 27.5847 32.1653C28.1708 32.7513 28.5 33.5462 28.5 34.375V78.125C28.5 78.9538 28.1708 79.7487 27.5847 80.3347C26.9987 80.9208 26.2038 81.25 25.375 81.25C24.5462 81.25 23.7513 80.9208 23.1653 80.3347C22.5792 79.7487 22.25 78.9538 22.25 78.125V34.375C22.25 33.5462 22.5792 32.7513 23.1653 32.1653C23.7513 31.5792 24.5462 31.25 25.375 31.25ZM41 31.25C41.8288 31.25 42.6237 31.5792 43.2097 32.1653C43.7958 32.7513 44.125 33.5462 44.125 34.375V78.125C44.125 78.9538 43.7958 79.7487 43.2097 80.3347C42.6237 80.9208 41.8288 81.25 41 81.25C40.1712 81.25 39.3763 80.9208 38.7903 80.3347C38.2042 79.7487 37.875 78.9538 37.875 78.125V34.375C37.875 33.5462 38.2042 32.7513 38.7903 32.1653C39.3763 31.5792 40.1712 31.25 41 31.25ZM59.75 34.375V78.125C59.75 78.9538 59.4208 79.7487 58.8347 80.3347C58.2487 80.9208 57.4538 81.25 56.625 81.25C55.7962 81.25 55.0013 80.9208 54.4153 80.3347C53.8292 79.7487 53.5 78.9538 53.5 78.125V34.375C53.5 33.5462 53.8292 32.7513 54.4153 32.1653C55.0013 31.5792 55.7962 31.25 56.625 31.25C57.4538 31.25 58.2487 31.5792 58.8347 32.1653C59.4208 32.7513 59.75 33.5462 59.75 34.375Z" fill="var(--color-white)" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className='container-opt'>
                    <div className='header-section'>
                        <h3>Vectores Batientes</h3>
                        <button onClick={() => handleAddVector('batiente')}>
                            <svg width="20" height="20" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38 0.5C38.8288 0.5 39.6237 0.829241 40.2097 1.41529C40.7958 2.00134 41.125 2.7962 41.125 3.625V34.875H72.375C73.2038 34.875 73.9987 35.2042 74.5847 35.7903C75.1708 36.3763 75.5 37.1712 75.5 38C75.5 38.8288 75.1708 39.6237 74.5847 40.2097C73.9987 40.7958 73.2038 41.125 72.375 41.125H41.125V72.375C41.125 73.2038 40.7958 73.9987 40.2097 74.5847C39.6237 75.1708 38.8288 75.5 38 75.5C37.1712 75.5 36.3763 75.1708 35.7903 74.5847C35.2042 73.9987 34.875 73.2038 34.875 72.375V41.125H3.625C2.7962 41.125 2.00134 40.7958 1.41529 40.2097C0.829241 39.6237 0.5 38.8288 0.5 38C0.5 37.1712 0.829241 36.3763 1.41529 35.7903C2.00134 35.2042 2.7962 34.875 3.625 34.875H34.875V3.625C34.875 2.7962 35.2042 2.00134 35.7903 1.41529C36.3763 0.829241 37.1712 0.5 38 0.5Z" fill="var(--color-white)" />
                            </svg>
                        </button>
                    </div>
                    <table>
                        <tbody>
                            {batientes.map((batiente) => (
                                <tr key={batiente.id}>
                                    <td style={{ maxWidth: '100px' }}>
                                        <img src={batiente.svg_url} alt={batiente.name} width="50" height="50" />
                                    </td>
                                    <td>{batiente.name}</td>
                                    <td style={{ maxWidth: '50px' }}>
                                        <button className='button-delete' onClick={() => handleDeleteVector(batiente.id, 'batiente')}>
                                            <svg width="20" height="20" viewBox="0 0 82 94" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.625 6.25C4.9674 6.25 3.37768 6.90848 2.20558 8.08058C1.03348 9.25268 0.375 10.8424 0.375 12.5V18.75C0.375 20.4076 1.03348 21.9973 2.20558 23.1694C3.37768 24.3415 4.9674 25 6.625 25H9.75V81.25C9.75 84.5652 11.067 87.7446 13.4112 90.0888C15.7554 92.433 18.9348 93.75 22.25 93.75H59.75C63.0652 93.75 66.2446 92.433 68.5888 90.0888C70.933 87.7446 72.25 84.5652 72.25 81.25V25H75.375C77.0326 25 78.6223 24.3415 79.7944 23.1694C80.9665 21.9973 81.625 20.4076 81.625 18.75V12.5C81.625 10.8424 80.9665 9.25268 79.7944 8.08058C78.6223 6.90848 77.0326 6.25 75.375 6.25H53.5C53.5 4.5924 52.8415 3.00269 51.6694 1.83058C50.4973 0.65848 48.9076 0 47.25 0L34.75 0C33.0924 0 31.5027 0.65848 30.3306 1.83058C29.1585 3.00269 28.5 4.5924 28.5 6.25H6.625ZM25.375 31.25C26.2038 31.25 26.9987 31.5792 27.5847 32.1653C28.1708 32.7513 28.5 33.5462 28.5 34.375V78.125C28.5 78.9538 28.1708 79.7487 27.5847 80.3347C26.9987 80.9208 26.2038 81.25 25.375 81.25C24.5462 81.25 23.7513 80.9208 23.1653 80.3347C22.5792 79.7487 22.25 78.9538 22.25 78.125V34.375C22.25 33.5462 22.5792 32.7513 23.1653 32.1653C23.7513 31.5792 24.5462 31.25 25.375 31.25ZM41 31.25C41.8288 31.25 42.6237 31.5792 43.2097 32.1653C43.7958 32.7513 44.125 33.5462 44.125 34.375V78.125C44.125 78.9538 43.7958 79.7487 43.2097 80.3347C42.6237 80.9208 41.8288 81.25 41 81.25C40.1712 81.25 39.3763 80.9208 38.7903 80.3347C38.2042 79.7487 37.875 78.9538 37.875 78.125V34.375C37.875 33.5462 38.2042 32.7513 38.7903 32.1653C39.3763 31.5792 40.1712 31.25 41 31.25ZM59.75 34.375V78.125C59.75 78.9538 59.4208 79.7487 58.8347 80.3347C58.2487 80.9208 57.4538 81.25 56.625 81.25C55.7962 81.25 55.0013 80.9208 54.4153 80.3347C53.8292 79.7487 53.5 78.9538 53.5 78.125V34.375C53.5 33.5462 53.8292 32.7513 54.4153 32.1653C55.0013 31.5792 55.7962 31.25 56.625 31.25C57.4538 31.25 58.2487 31.5792 58.8347 32.1653C59.4208 32.7513 59.75 33.5462 59.75 34.375Z" fill="var(--color-white)" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className='container-opt'>
                    <div className='header-section'>
                        <h3>Vectores Deslizantes</h3>
                        <button onClick={() => handleAddVector('deslizante')}>
                            <svg width="20" height="20" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38 0.5C38.8288 0.5 39.6237 0.829241 40.2097 1.41529C40.7958 2.00134 41.125 2.7962 41.125 3.625V34.875H72.375C73.2038 34.875 73.9987 35.2042 74.5847 35.7903C75.1708 36.3763 75.5 37.1712 75.5 38C75.5 38.8288 75.1708 39.6237 74.5847 40.2097C73.9987 40.7958 73.2038 41.125 72.375 41.125H41.125V72.375C41.125 73.2038 40.7958 73.9987 40.2097 74.5847C39.6237 75.1708 38.8288 75.5 38 75.5C37.1712 75.5 36.3763 75.1708 35.7903 74.5847C35.2042 73.9987 34.875 73.2038 34.875 72.375V41.125H3.625C2.7962 41.125 2.00134 40.7958 1.41529 40.2097C0.829241 39.6237 0.5 38.8288 0.5 38C0.5 37.1712 0.829241 36.3763 1.41529 35.7903C2.00134 35.2042 2.7962 34.875 3.625 34.875H34.875V3.625C34.875 2.7962 35.2042 2.00134 35.7903 1.41529C36.3763 0.829241 37.1712 0.5 38 0.5Z" fill="var(--color-white)" />
                            </svg>
                        </button>
                    </div>
                    <table>
                        <tbody>
                            {deslizantes.map((deslizante) => (
                                <tr key={deslizante.id}>
                                    <td style={{ maxWidth: '100px' }}>
                                        <img src={deslizante.svg_url} alt={deslizante.name} width="50" height="50" />
                                    </td>
                                    <td>{deslizante.name}</td>
                                    <td style={{ maxWidth: '50px' }}>
                                        <button className='button-delete' onClick={() => handleDeleteVector(deslizante.id, 'deslizante')}>
                                            <svg width="20" height="20" viewBox="0 0 82 94" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.625 6.25C4.9674 6.25 3.37768 6.90848 2.20558 8.08058C1.03348 9.25268 0.375 10.8424 0.375 12.5V18.75C0.375 20.4076 1.03348 21.9973 2.20558 23.1694C3.37768 24.3415 4.9674 25 6.625 25H9.75V81.25C9.75 84.5652 11.067 87.7446 13.4112 90.0888C15.7554 92.433 18.9348 93.75 22.25 93.75H59.75C63.0652 93.75 66.2446 92.433 68.5888 90.0888C70.933 87.7446 72.25 84.5652 72.25 81.25V25H75.375C77.0326 25 78.6223 24.3415 79.7944 23.1694C80.9665 21.9973 81.625 20.4076 81.625 18.75V12.5C81.625 10.8424 80.9665 9.25268 79.7944 8.08058C78.6223 6.90848 77.0326 6.25 75.375 6.25H53.5C53.5 4.5924 52.8415 3.00269 51.6694 1.83058C50.4973 0.65848 48.9076 0 47.25 0L34.75 0C33.0924 0 31.5027 0.65848 30.3306 1.83058C29.1585 3.00269 28.5 4.5924 28.5 6.25H6.625ZM25.375 31.25C26.2038 31.25 26.9987 31.5792 27.5847 32.1653C28.1708 32.7513 28.5 33.5462 28.5 34.375V78.125C28.5 78.9538 28.1708 79.7487 27.5847 80.3347C26.9987 80.9208 26.2038 81.25 25.375 81.25C24.5462 81.25 23.7513 80.9208 23.1653 80.3347C22.5792 79.7487 22.25 78.9538 22.25 78.125V34.375C22.25 33.5462 22.5792 32.7513 23.1653 32.1653C23.7513 31.5792 24.5462 31.25 25.375 31.25ZM41 31.25C41.8288 31.25 42.6237 31.5792 43.2097 32.1653C43.7958 32.7513 44.125 33.5462 44.125 34.375V78.125C44.125 78.9538 43.7958 79.7487 43.2097 80.3347C42.6237 80.9208 41.8288 81.25 41 81.25C40.1712 81.25 39.3763 80.9208 38.7903 80.3347C38.2042 79.7487 37.875 78.9538 37.875 78.125V34.375C37.875 33.5462 38.2042 32.7513 38.7903 32.1653C39.3763 31.5792 40.1712 31.25 41 31.25ZM59.75 34.375V78.125C59.75 78.9538 59.4208 79.7487 58.8347 80.3347C58.2487 80.9208 57.4538 81.25 56.625 81.25C55.7962 81.25 55.0013 80.9208 54.4153 80.3347C53.8292 79.7487 53.5 78.9538 53.5 78.125V34.375C53.5 33.5462 53.8292 32.7513 54.4153 32.1653C55.0013 31.5792 55.7962 31.25 56.625 31.25C57.4538 31.25 58.2487 31.5792 58.8347 32.1653C59.4208 32.7513 59.75 33.5462 59.75 34.375Z" fill="var(--color-white)" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Repite lo mismo para Vectores deslizantes, Líneas, Colores y Ubicaciones */}

                {modalVisible.vectores && <Modal_configuracion_vectores onClose={() => {
                    setModalVisible({ vectores: false, other: false });
                }} data={modalData} onVectorAdded={handleVectorAdded} />}

                {modalVisible.other && <Modal_configuracion_other onClose={() => {
                    setModalVisible({ vectores: false, other: false });
                }} data={modalData} onOtherAdded={handleOtherAdded} />}
            </div>
        </div>
        
        <Dialog
            {...dialogProps}
            onClose={() => {
                setDialogProps({ show: false })
            }}
        />
    </>
};

export default Dashboard_admin_configuracion;