import React, { useState } from 'react';
import axios from 'axios';
import './../components/Login.css';
import * as globals from './../components/all.js';
import { Dialog } from '../components/Dialog.jsx';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [dialogProps, setDialogProps] = useState({show: false})

    let path_api = localStorage.getItem('path_api');
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        globals.openViewCharge();
        await axios.post(`${path_api}/api/login`, {
            email,
            password
        }).then(response => {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            if(response.data.user.lvl == 1){
                window.location.href = '/dashboard/admin';
            }else if(response.data.user.lvl == 2){
                window.location.href = '/';
            }
        }).catch(error => {
            console.error('Error:', error);
            setDialogProps({show: true, message: 'Invalid credentials'});
        });
        globals.closeViewCharge();
    };
  
    return <>
        <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
            <input className="login-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
            <input className="login-input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            <button className="login-button" type="submit">Login</button>
        </form>
        </div>
        <Dialog {...dialogProps} onClose={()=>{
            setDialogProps({show: false})
        }} />
    </>
  };
  
  export default Login;