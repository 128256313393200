import React, { useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import * as globals from './components/all.js';
import './components/all.css';
import Login from './screens/Login';
import Dashboard_admin from './screens/Dashboard_admin';
import Vendedor_static from './screens/Vendedor_static';
import Vendedor_proyectos from './screens/Vendedor_proyectos';
import Vendedor_items from './screens/Vendedor_items';
import SignaturePad from './screens/SignaturePad';
import Dashboard_admin_default from './screens/admin/Dashboard_admin_default';
import Dashboard_admin_ordenes from './screens/admin/Dashboard_admin_ordenes';
import Dashboard_admin_vendedores from './screens/admin/Dashboard_admin_vendedores';
import Dashboard_admin_configuracion from './screens/admin/Dashboard_admin_configuracion';
import './App.css';

function App() {
    let user = JSON.parse(localStorage.getItem('user'));

    //localStorage.setItem('path_api', 'http://localhost:8000');
    localStorage.setItem('path_api', 'https://apivindu.internow.com.mx/public');

    useEffect(() => {
        globals.closeViewCharge();
    }, []);

    function isAuthenticated() {
        return !!localStorage.getItem('token');
    }

    return (
        <div className='app'>
            <div id="screenBlackAll"></div>
            <div id="carga"></div>
            <Router>
                <Routes>
                    <Route path="/Login" element={<Login/>}></Route>
                    <Route path="/dashboard/admin/" element={isAuthenticated() && user?.lvl === 1 ? <Dashboard_admin /> : <Navigate to="/login" replace />}>
                        <Route index element={<Dashboard_admin_default/>}></Route>
                        <Route path="/dashboard/admin/ordenes" element={<Dashboard_admin_ordenes/>}></Route>
                        <Route path="/dashboard/admin/vendedores" element={<Dashboard_admin_vendedores/>}></Route>
                        <Route path="/dashboard/admin/configuracion" element={<Dashboard_admin_configuracion/>}></Route>
                    </Route>
                    <Route path="/" element={isAuthenticated() && user?.lvl === 2 ? <Vendedor_static /> : <Navigate to="/login" replace />}>
                        <Route index element={<Vendedor_proyectos/>}></Route>
                        <Route path="/items/:id" element={<Vendedor_items/>}></Route>
                        <Route path="/firma/:id" element={<SignaturePad/>}></Route>
                    </Route>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
