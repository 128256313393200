import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as globals from './../../components/all.js';
import { Dialog } from '../../components/Dialog.jsx';

const Dashboard_admin_vendedores = () => {
    const [sellers, setSellers] = useState([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [newSeller, setNewSeller] = useState({ name: '', email: '', password: '', confirmPassword: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [sellerToEdit, setSellerToEdit] = useState(null); 
    const [dialogProps, setDialogProps] = useState({show: false});
    const user = JSON.parse(localStorage.getItem('user'));
    let path_api = localStorage.getItem('path_api');

    useEffect(() => {
        if(!user) return;
        fetchSellers();
    }, [search, currentPage]);

    const fetchSellers = async () => {
        await axios.get(`${path_api}/api/vendedores?type=all&lvl=${user.lvl}`, { params: { search, page: currentPage } })
        .then(response => {
            setSellers(response.data.data);
            setTotalPages(response.data.last_page); 
        })
        .catch(error => {
            console.error('Hubo un error al obtener los vendedores:', error);
            setDialogProps({message: 'Hubo un error al obtener los vendedores', show: true});
        });
    };
    

    const handleAddSeller = async () => {
        if (newSeller.password !== newSeller.confirmPassword) {
            setDialogProps({message: 'Las contraseñas no coinciden', show: true});
            return;
        }

        globals.openViewCharge();
        await axios.post(`${path_api}/api/vendedores?lvl=${user.lvl}`, newSeller)
        .then(response => {
            setIsModalOpen(false);
            fetchSellers();
        })
        .catch(error => {
            console.error('Hubo un error al agregar vendedor:', error);
            setDialogProps({message: 'Hubo un error al agregar vendedor', show: true});
        });
        globals.closeViewCharge(); 
    };

    const handleEditSeller  = async () => {
        if (sellerToEdit.password !== sellerToEdit.confirmPassword) {
            setDialogProps({message: 'Las contraseñas no coinciden', show: true});
            return;
        }

        const updatedSeller = {
            name: sellerToEdit.name,
            email: sellerToEdit.email,
            password: sellerToEdit.password,
        };

        globals.openViewCharge();
        await axios.put(`${path_api}/api/vendedores/${sellerToEdit.id}?lvl=${user.lvl}`, updatedSeller)
        .then(response => {
            setIsModalOpen(false);
            setIsEditMode(false);
            setSellerToEdit(null);
            fetchSellers();
        })
        .catch(error => {
            console.error('Hubo un error al actualizar vendedor:', error);
            setDialogProps({message: 'Hubo un error al actualizar vendedor', show: true});
        });
        globals.closeViewCharge(); 
    };

    const handleDelete = async (id) => {
        globals.openViewCharge();
        await axios.delete(`${path_api}/api/vendedores/${id}?lvl=${user.lvl}`)
        .then(response => {
            fetchSellers();
        })
        .catch(error => {
            console.error('Hubo un error al eliminar vendedor:', error);
            setDialogProps({message: 'Hubo un error al eliminar vendedor', show: true});
        });
        globals.closeViewCharge(); 
    };

    const openEditModal = (seller) => {
        setSellerToEdit(seller);
        setIsModalOpen(true);
        setIsEditMode(true);
    };

    const changePage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return <>
        <div className='flex-column gap-40'>
            <h2>Vendedores</h2>
            <div className='container-nav-search'>
                <div>
                    <label>Filtrar</label>
                    <input
                        type="text"
                        placeholder="Escribe para filtrar por nombre o correo"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <button onClick={() => {
                    setNewSeller({ name: '', email: '', password: '', confirmPassword: '' });
                    setIsModalOpen(true);
                    setIsEditMode(false);
                }}>
                    Agregar Vendedor
                </button>
            </div>

            {isModalOpen && (
                <div className='modal-container'>
                    <div className="modal">
                        <h2>{isEditMode ? 'Editar Vendedor' : 'Agregar Vendedor'}</h2>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label>Nombre: </label>
                                <input
                                    type="text"
                                    placeholder="Nombre"
                                    value={isEditMode ? sellerToEdit.name : newSeller.name}
                                    onChange={(e) => isEditMode
                                        ? setSellerToEdit({ ...sellerToEdit, name: e.target.value })
                                        : setNewSeller({ ...newSeller, name: e.target.value })}
                                />
                            </div>
                            <div className='flex-column gap-5'>
                                <label>Correo: </label>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={isEditMode ? sellerToEdit.email : newSeller.email}
                                    onChange={(e) => isEditMode
                                        ? setSellerToEdit({ ...sellerToEdit, email: e.target.value })
                                        : setNewSeller({ ...newSeller, email: e.target.value })}
                                />
                                </div>
                        </div>
                        <div className='flex-row gap-20'>
                            <div className='flex-column gap-5'>
                                <label>Contraseña: </label>
                                <input
                                    type="password"
                                    placeholder={isEditMode ? "Nueva Contraseña (opcional)" : "Contraseña"}
                                    value={isEditMode ? sellerToEdit.password : newSeller.password}
                                    onChange={(e) => isEditMode
                                        ? setSellerToEdit({ ...sellerToEdit, password: e.target.value })
                                        : setNewSeller({ ...newSeller, password: e.target.value })}
                                />
                            </div>
                            <div className='flex-column gap-5'>
                                <label>Confirmación: </label>
                                <input
                                    type="password"
                                    placeholder={isEditMode ? "Confirmar Nueva Contraseña" : "Confirmar Contraseña"}
                                    value={isEditMode ? sellerToEdit.confirmPassword : newSeller.confirmPassword}
                                    onChange={(e) => isEditMode
                                        ? setSellerToEdit({ ...sellerToEdit, confirmPassword: e.target.value })
                                        : setNewSeller({ ...newSeller, confirmPassword: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='flex-row gap-20 flex-end'>
                            <button className='cancel' onClick={() => setIsModalOpen(false)}>Cancelar</button>
                            <button onClick={isEditMode ? handleEditSeller : handleAddSeller}>
                                {isEditMode ? 'Guardar Cambios' : 'Agregar'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className='container-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Corero</th>
                            <th style={{maxWidth: '200px'}}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sellers.map((seller) => (
                            <tr key={seller.id}>
                                <td>{seller.name}</td>
                                <td>{seller.email}</td>
                                <td className='gap-20' style={{maxWidth: '200px'}}>
                                    <button className='button-edit' onClick={() => openEditModal(seller)}>
                                        <svg width="20" height="20" viewBox="0 0 92 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M90.8875 9.125C91.4714 9.71077 91.7993 10.5041 91.7993 11.3312C91.7993 12.1584 91.4714 12.9517 90.8875 13.5375L84.3687 20.0625L71.8687 7.5625L78.3875 1.0375C78.9735 0.451653 79.7682 0.122542 80.5969 0.122542C81.4255 0.122542 82.2202 0.451653 82.8062 1.0375L90.8875 9.11875V9.125ZM79.95 24.475L67.45 11.975L24.8688 54.5625C24.5248 54.9064 24.2658 55.3259 24.1125 55.7875L19.0812 70.875C18.99 71.15 18.977 71.4451 19.0438 71.7271C19.1106 72.009 19.2545 72.2669 19.4594 72.4718C19.6643 72.6767 19.9222 72.8206 20.2042 72.8874C20.4862 72.9542 20.7812 72.9412 21.0562 72.85L36.1437 67.8187C36.6048 67.6673 37.0242 67.4105 37.3688 67.0688L79.95 24.475Z" fill="var(--color-white)" />
                                            <path d="M0.25 81.375C0.25 83.8614 1.23772 86.246 2.99587 88.0041C4.75403 89.7623 7.1386 90.75 9.625 90.75H78.375C80.8614 90.75 83.246 89.7623 85.0041 88.0041C86.7623 86.246 87.75 83.8614 87.75 81.375V43.875C87.75 43.0462 87.4208 42.2513 86.8347 41.6653C86.2487 41.0792 85.4538 40.75 84.625 40.75C83.7962 40.75 83.0013 41.0792 82.4153 41.6653C81.8292 42.2513 81.5 43.0462 81.5 43.875V81.375C81.5 82.2038 81.1708 82.9987 80.5847 83.5847C79.9987 84.1708 79.2038 84.5 78.375 84.5H9.625C8.7962 84.5 8.00134 84.1708 7.41529 83.5847C6.82924 82.9987 6.5 82.2038 6.5 81.375V12.625C6.5 11.7962 6.82924 11.0013 7.41529 10.4153C8.00134 9.82924 8.7962 9.5 9.625 9.5H50.25C51.0788 9.5 51.8737 9.17076 52.4597 8.58471C53.0458 7.99866 53.375 7.2038 53.375 6.375C53.375 5.5462 53.0458 4.75134 52.4597 4.16529C51.8737 3.57924 51.0788 3.25 50.25 3.25H9.625C7.1386 3.25 4.75403 4.23772 2.99587 5.99587C1.23772 7.75403 0.25 10.1386 0.25 12.625V81.375Z" fill="var(--color-white)"/>
                                        </svg>
                                    </button>
                                    <button className='button-delete' onClick={() => handleDelete(seller.id)}>
                                        <svg width="20" height="20" viewBox="0 0 82 94" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.625 6.25C4.9674 6.25 3.37768 6.90848 2.20558 8.08058C1.03348 9.25268 0.375 10.8424 0.375 12.5V18.75C0.375 20.4076 1.03348 21.9973 2.20558 23.1694C3.37768 24.3415 4.9674 25 6.625 25H9.75V81.25C9.75 84.5652 11.067 87.7446 13.4112 90.0888C15.7554 92.433 18.9348 93.75 22.25 93.75H59.75C63.0652 93.75 66.2446 92.433 68.5888 90.0888C70.933 87.7446 72.25 84.5652 72.25 81.25V25H75.375C77.0326 25 78.6223 24.3415 79.7944 23.1694C80.9665 21.9973 81.625 20.4076 81.625 18.75V12.5C81.625 10.8424 80.9665 9.25268 79.7944 8.08058C78.6223 6.90848 77.0326 6.25 75.375 6.25H53.5C53.5 4.5924 52.8415 3.00269 51.6694 1.83058C50.4973 0.65848 48.9076 0 47.25 0L34.75 0C33.0924 0 31.5027 0.65848 30.3306 1.83058C29.1585 3.00269 28.5 4.5924 28.5 6.25H6.625ZM25.375 31.25C26.2038 31.25 26.9987 31.5792 27.5847 32.1653C28.1708 32.7513 28.5 33.5462 28.5 34.375V78.125C28.5 78.9538 28.1708 79.7487 27.5847 80.3347C26.9987 80.9208 26.2038 81.25 25.375 81.25C24.5462 81.25 23.7513 80.9208 23.1653 80.3347C22.5792 79.7487 22.25 78.9538 22.25 78.125V34.375C22.25 33.5462 22.5792 32.7513 23.1653 32.1653C23.7513 31.5792 24.5462 31.25 25.375 31.25ZM41 31.25C41.8288 31.25 42.6237 31.5792 43.2097 32.1653C43.7958 32.7513 44.125 33.5462 44.125 34.375V78.125C44.125 78.9538 43.7958 79.7487 43.2097 80.3347C42.6237 80.9208 41.8288 81.25 41 81.25C40.1712 81.25 39.3763 80.9208 38.7903 80.3347C38.2042 79.7487 37.875 78.9538 37.875 78.125V34.375C37.875 33.5462 38.2042 32.7513 38.7903 32.1653C39.3763 31.5792 40.1712 31.25 41 31.25ZM59.75 34.375V78.125C59.75 78.9538 59.4208 79.7487 58.8347 80.3347C58.2487 80.9208 57.4538 81.25 56.625 81.25C55.7962 81.25 55.0013 80.9208 54.4153 80.3347C53.8292 79.7487 53.5 78.9538 53.5 78.125V34.375C53.5 33.5462 53.8292 32.7513 54.4153 32.1653C55.0013 31.5792 55.7962 31.25 56.625 31.25C57.4538 31.25 58.2487 31.5792 58.8347 32.1653C59.4208 32.7513 59.75 33.5462 59.75 34.375Z" fill="var(--color-white)"/>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination">
                    <div>
                        <span>Mostrando <b>{sellers.length}</b> vendedores</span>
                    </div>
                    <div>
                        <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>Página <b>{currentPage}</b> de <b>{totalPages}</b></span>
                        <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog
            {...dialogProps}
            onClose={()=>{
                setDialogProps({show: false})
            }}
        />
    </>;
};

export default Dashboard_admin_vendedores;