import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Logo from './../components/logo.jpg'
import './../components/NavbarTop.css';
import axios from 'axios';
import * as globals from './../components/all.js';
import { Dialog } from '../components/Dialog.jsx';

const NavbarTop = () => {
    const { id } = useParams();
    const [currentPath, setCurrentPath] = useState('');
    const [dialogProps, setDialogProps] = useState({ show: false })
    const location = useLocation();
    const navigate = useNavigate();
    let path_api = localStorage.getItem('path_api');
    let user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location]);

    const handleDelete_proyect = async () => {
        globals.openViewCharge();
        await axios.delete(`${path_api}/api/proyectos/${id}?lvl=${user.lvl}`)
            .then(response => {
                navigate(-1);
            })
            .catch(error => {
                console.error('Hubo un error al eliminar el proyecto:', error);
                setDialogProps({message: 'Hubo un error al eliminar el proyecto', show: true});
            });
        globals.closeViewCharge();
    };

    if (currentPath == '/')
        return (
            <div className="navbar-top">
                <div className='max-width-desk'>
                    <div className="navbar-left">
                        <img src={Logo} alt='Logo' />
                    </div>
                    <div className="navbar-right">
                        <h2>Lista de proyectos</h2>
                    </div>
                </div>
            </div>
        );
    else if (currentPath.startsWith('/items'))
        return (
            <div className="navbar-top">
                <div className='max-width-desk'>
                    <div className="navbar-left">
                        <Link to="/">
                            <svg width="40" viewBox="0 0 88 58" xmlns="http://www.w3.org/2000/svg">
                                <path d="M87.75 29C87.75 28.1712 87.4208 27.3763 86.8347 26.7903C86.2487 26.2042 85.4538 25.875 84.625 25.875H10.9188L30.5875 6.2125C30.878 5.92195 31.1085 5.57702 31.2658 5.1974C31.423 4.81778 31.5039 4.4109 31.5039 4C31.5039 3.5891 31.423 3.18222 31.2658 2.8026C31.1085 2.42298 30.878 2.07805 30.5875 1.7875C30.2969 1.49695 29.952 1.26647 29.5724 1.10923C29.1928 0.951985 28.7859 0.871052 28.375 0.871052C27.9641 0.871052 27.5572 0.951985 27.1776 1.10923C26.798 1.26647 26.453 1.49695 26.1625 1.7875L1.1625 26.7875C0.87148 27.0778 0.640587 27.4226 0.483047 27.8023C0.325506 28.1819 0.244414 28.589 0.244414 29C0.244414 29.411 0.325506 29.818 0.483047 30.1977C0.640587 30.5774 0.87148 30.9222 1.1625 31.2125L26.1625 56.2125C26.453 56.503 26.798 56.7335 27.1776 56.8908C27.5572 57.048 27.9641 57.1289 28.375 57.1289C28.7859 57.1289 29.1928 57.048 29.5724 56.8908C29.952 56.7335 30.2969 56.503 30.5875 56.2125C30.878 55.9219 31.1085 55.577 31.2658 55.1974C31.423 54.8178 31.5039 54.4109 31.5039 54C31.5039 53.5891 31.423 53.1822 31.2658 52.8026C31.1085 52.423 30.878 52.078 30.5875 51.7875L10.9188 32.125H84.625C85.4538 32.125 86.2487 31.7958 86.8347 31.2097C87.4208 30.6237 87.75 29.8288 87.75 29Z" fill="var(--color-white)" />
                            </svg>
                        </Link>
                    </div>
                    <div className="navbar-right">
                        <h2>Items del proyecto</h2>
                        <button className='button-delete' onClick={() => handleDelete_proyect()}>
                            <svg width="20" height="20" viewBox="0 0 82 94" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.625 6.25C4.9674 6.25 3.37768 6.90848 2.20558 8.08058C1.03348 9.25268 0.375 10.8424 0.375 12.5V18.75C0.375 20.4076 1.03348 21.9973 2.20558 23.1694C3.37768 24.3415 4.9674 25 6.625 25H9.75V81.25C9.75 84.5652 11.067 87.7446 13.4112 90.0888C15.7554 92.433 18.9348 93.75 22.25 93.75H59.75C63.0652 93.75 66.2446 92.433 68.5888 90.0888C70.933 87.7446 72.25 84.5652 72.25 81.25V25H75.375C77.0326 25 78.6223 24.3415 79.7944 23.1694C80.9665 21.9973 81.625 20.4076 81.625 18.75V12.5C81.625 10.8424 80.9665 9.25268 79.7944 8.08058C78.6223 6.90848 77.0326 6.25 75.375 6.25H53.5C53.5 4.5924 52.8415 3.00269 51.6694 1.83058C50.4973 0.65848 48.9076 0 47.25 0L34.75 0C33.0924 0 31.5027 0.65848 30.3306 1.83058C29.1585 3.00269 28.5 4.5924 28.5 6.25H6.625ZM25.375 31.25C26.2038 31.25 26.9987 31.5792 27.5847 32.1653C28.1708 32.7513 28.5 33.5462 28.5 34.375V78.125C28.5 78.9538 28.1708 79.7487 27.5847 80.3347C26.9987 80.9208 26.2038 81.25 25.375 81.25C24.5462 81.25 23.7513 80.9208 23.1653 80.3347C22.5792 79.7487 22.25 78.9538 22.25 78.125V34.375C22.25 33.5462 22.5792 32.7513 23.1653 32.1653C23.7513 31.5792 24.5462 31.25 25.375 31.25ZM41 31.25C41.8288 31.25 42.6237 31.5792 43.2097 32.1653C43.7958 32.7513 44.125 33.5462 44.125 34.375V78.125C44.125 78.9538 43.7958 79.7487 43.2097 80.3347C42.6237 80.9208 41.8288 81.25 41 81.25C40.1712 81.25 39.3763 80.9208 38.7903 80.3347C38.2042 79.7487 37.875 78.9538 37.875 78.125V34.375C37.875 33.5462 38.2042 32.7513 38.7903 32.1653C39.3763 31.5792 40.1712 31.25 41 31.25ZM59.75 34.375V78.125C59.75 78.9538 59.4208 79.7487 58.8347 80.3347C58.2487 80.9208 57.4538 81.25 56.625 81.25C55.7962 81.25 55.0013 80.9208 54.4153 80.3347C53.8292 79.7487 53.5 78.9538 53.5 78.125V34.375C53.5 33.5462 53.8292 32.7513 54.4153 32.1653C55.0013 31.5792 55.7962 31.25 56.625 31.25C57.4538 31.25 58.2487 31.5792 58.8347 32.1653C59.4208 32.7513 59.75 33.5462 59.75 34.375Z" fill="var(--color-white)" />
                            </svg>
                        </button>
                    </div>
                </div>
                <Dialog
                    {...dialogProps}
                    onClose={() => {
                        setDialogProps({ show: false })
                    }}
                />
            </div>
        );
    else if (currentPath.startsWith('/firma'))
        return (
            <div className="navbar-top">
                <div className='max-width-desk'>
                    <div style={{ cursor: 'pointer' }} className="navbar-left" onClick={() => navigate(-1)}>
                        <svg width="40" viewBox="0 0 88 58" xmlns="http://www.w3.org/2000/svg">
                            <path d="M87.75 29C87.75 28.1712 87.4208 27.3763 86.8347 26.7903C86.2487 26.2042 85.4538 25.875 84.625 25.875H10.9188L30.5875 6.2125C30.878 5.92195 31.1085 5.57702 31.2658 5.1974C31.423 4.81778 31.5039 4.4109 31.5039 4C31.5039 3.5891 31.423 3.18222 31.2658 2.8026C31.1085 2.42298 30.878 2.07805 30.5875 1.7875C30.2969 1.49695 29.952 1.26647 29.5724 1.10923C29.1928 0.951985 28.7859 0.871052 28.375 0.871052C27.9641 0.871052 27.5572 0.951985 27.1776 1.10923C26.798 1.26647 26.453 1.49695 26.1625 1.7875L1.1625 26.7875C0.87148 27.0778 0.640587 27.4226 0.483047 27.8023C0.325506 28.1819 0.244414 28.589 0.244414 29C0.244414 29.411 0.325506 29.818 0.483047 30.1977C0.640587 30.5774 0.87148 30.9222 1.1625 31.2125L26.1625 56.2125C26.453 56.503 26.798 56.7335 27.1776 56.8908C27.5572 57.048 27.9641 57.1289 28.375 57.1289C28.7859 57.1289 29.1928 57.048 29.5724 56.8908C29.952 56.7335 30.2969 56.503 30.5875 56.2125C30.878 55.9219 31.1085 55.577 31.2658 55.1974C31.423 54.8178 31.5039 54.4109 31.5039 54C31.5039 53.5891 31.423 53.1822 31.2658 52.8026C31.1085 52.423 30.878 52.078 30.5875 51.7875L10.9188 32.125H84.625C85.4538 32.125 86.2487 31.7958 86.8347 31.2097C87.4208 30.6237 87.75 29.8288 87.75 29Z" fill="var(--color-white)" />
                        </svg>
                    </div>
                    <div className="navbar-right">
                        <h2>Firma electronica</h2>
                    </div>
                </div>
            </div>
        );
};

export default NavbarTop;